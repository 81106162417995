import { dew as _constantsDew } from "./constants";
import { dew as _packagePayloadDew } from "./package-payload";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unpackPayload = void 0;

  var constants_1 = _constantsDew();

  var package_payload_1 = _packagePayloadDew();

  function unpackPayload(e) {
    var payload;

    if (e.data.slice(0, constants_1.prefix.length) !== constants_1.prefix) {
      return false;
    }

    try {
      payload = JSON.parse(e.data.slice(constants_1.prefix.length));
    } catch (err) {
      return false;
    }

    if (payload.reply) {
      var replyOrigin_1 = e.origin;
      var replySource_1 = e.source;
      var replyEvent_1 = payload.reply;

      payload.reply = function reply(replyData) {
        if (!replySource_1) {
          return;
        }

        var replyPayload = (0, package_payload_1.packagePayload)(replyEvent_1, replyOrigin_1, replyData);

        if (!replyPayload) {
          return;
        }

        replySource_1.postMessage(replyPayload, replyOrigin_1);
      };
    }

    return payload;
  }

  exports.unpackPayload = unpackPayload;
  return exports;
}