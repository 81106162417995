var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasOpener = void 0;

  function hasOpener(frame) {
    if (frame.top !== frame) {
      return false;
    }

    if (frame.opener == null) {
      return false;
    }

    if (frame.opener === frame) {
      return false;
    }

    if (frame.opener.closed === true) {
      return false;
    }

    return true;
  }

  exports.hasOpener = hasOpener;
  return exports;
}