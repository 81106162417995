import { dew as _attachDew } from "./lib/attach";
import { dew as _framebusDew } from "./framebus";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var attach_1 = _attachDew();

  var framebus_1 = _framebusDew();

  (0, attach_1.attach)();
  exports = framebus_1.Framebus;
  return exports;
}