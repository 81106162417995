import { dew as _broadcastDew } from "./broadcast";
import { dew as _constantsDew } from "./constants";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.broadcastToChildWindows = void 0;

  var broadcast_1 = _broadcastDew();

  var constants_1 = _constantsDew();

  function broadcastToChildWindows(payload, origin, source) {
    for (var i = constants_1.childWindows.length - 1; i >= 0; i--) {
      var childWindow = constants_1.childWindows[i];

      if (childWindow.closed) {
        constants_1.childWindows.splice(i, 1);
      } else if (source !== childWindow) {
        (0, broadcast_1.broadcast)(payload, {
          origin: origin,
          frame: childWindow.top
        });
      }
    }
  }

  exports.broadcastToChildWindows = broadcastToChildWindows;
  return exports;
}