import { dew as _constantsDew } from "./constants";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dispatch = void 0;

  var constants_1 = _constantsDew();

  function dispatch(origin, event, data, reply, e) {
    if (!constants_1.subscribers[origin]) {
      return;
    }

    if (!constants_1.subscribers[origin][event]) {
      return;
    }

    var args = [];

    if (data) {
      args.push(data);
    }

    if (reply) {
      args.push(reply);
    }

    for (var i = 0; i < constants_1.subscribers[origin][event].length; i++) {
      constants_1.subscribers[origin][event][i].apply(e, args);
    }
  }

  exports.dispatch = dispatch;
  return exports;
}