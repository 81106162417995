import { dew as _messageDew } from "./message";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.detach = exports.attach = void 0;

  var message_1 = _messageDew();

  var isAttached = false;

  function attach() {
    if (isAttached || typeof window === "undefined") {
      return;
    }

    isAttached = true;
    window.addEventListener("message", message_1.onmessage, false);
  }

  exports.attach = attach; // removeIf(production)

  function detach() {
    isAttached = false;
    window.removeEventListener("message", message_1.onmessage, false);
  }

  exports.detach = detach; // endRemoveIf(production)

  return exports;
}