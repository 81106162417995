import { dew as _isNotStringDew } from "./is-not-string";
import { dew as _unpackPayloadDew } from "./unpack-payload";
import { dew as _dispatchDew } from "./dispatch";
import { dew as _broadcastToChildWindowsDew } from "./broadcast-to-child-windows";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.onmessage = void 0;

  var is_not_string_1 = _isNotStringDew();

  var unpack_payload_1 = _unpackPayloadDew();

  var dispatch_1 = _dispatchDew();

  var broadcast_to_child_windows_1 = _broadcastToChildWindowsDew();

  function onmessage(e) {
    if ((0, is_not_string_1.isntString)(e.data)) {
      return;
    }

    var payload = (0, unpack_payload_1.unpackPayload)(e);

    if (!payload) {
      return;
    }

    var data = payload.eventData;
    var reply = payload.reply;
    (0, dispatch_1.dispatch)("*", payload.event, data, reply, e);
    (0, dispatch_1.dispatch)(e.origin, payload.event, data, reply, e);
    (0, broadcast_to_child_windows_1.broadcastToChildWindows)(e.data, payload.origin, e.source);
  }

  exports.onmessage = onmessage;
  return exports;
}