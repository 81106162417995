import { dew as _isNotStringDew } from "./is-not-string";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subscriptionArgsInvalid = void 0;

  var is_not_string_1 = _isNotStringDew();

  function subscriptionArgsInvalid(event, fn, origin) {
    if ((0, is_not_string_1.isntString)(event)) {
      return true;
    }

    if (typeof fn !== "function") {
      return true;
    }

    return (0, is_not_string_1.isntString)(origin);
  }

  exports.subscriptionArgsInvalid = subscriptionArgsInvalid;
  return exports;
}