import { dew as _hasOpenerDew } from "./has-opener";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.broadcast = void 0;

  var has_opener_1 = _hasOpenerDew();

  function broadcast(payload, options) {
    var i = 0;
    var frameToBroadcastTo;
    var origin = options.origin,
        frame = options.frame;

    try {
      frame.postMessage(payload, origin);

      if ((0, has_opener_1.hasOpener)(frame) && frame.opener.top !== window.top) {
        broadcast(payload, {
          origin: origin,
          frame: frame.opener.top
        });
      } // previously, our max value was frame.frames.length
      // but frames.length inherits from window.length
      // which can be overwritten if a developer does
      // `var length = value;` outside of a function
      // scope, it'll prevent us from looping through
      // all the frames. With this, we loop through
      // until there are no longer any frames
      // eslint-disable-next-line no-cond-assign


      while (frameToBroadcastTo = frame.frames[i]) {
        broadcast(payload, {
          origin: origin,
          frame: frameToBroadcastTo
        });
        i++;
      }
    } catch (_) {
      /* ignored */
    }
  }

  exports.broadcast = broadcast;
  return exports;
}